import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Auth0Provider } from "@auth0/auth0-react";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

const {
  AUTH0_clientId,
  AUTH0_domain,
  AUTH0_redirectUri,
  AUTH0_audience,
  AUTH0_scope,
  VERCEL_URL,
} = publicRuntimeConfig;


function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout;

  return (
    <>
      <Head>
        <title>PBG - Capital</title>
        <meta name="description" content="PBG - Capital. Coming Soon" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossOrigin="anonymous"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&family=Merriweather:wght@300;400;900&display=swap"
          rel="stylesheet"
        ></link>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Auth0Provider
        domain={AUTH0_domain!}
        clientId={AUTH0_clientId!}
        redirectUri={`${
          VERCEL_URL ? `https://back.pbg.capital` : "http://localhost:3000"
        }`}
        audience={AUTH0_audience!}
        scope={AUTH0_scope}
      >
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </Auth0Provider>
    </>
  );
}
export default MyApp;
